.dropzone__container button {
  background: none;
  display: block;
  border: none;
  outline: none;
  width: 100%;
}

.dropzone__container .dropzone {
  padding-block: 4rem;
  border: 2px dashed black;
}

.dropzone__container .dropzone p {
  text-align: center;
}

.draggable__item {
  display: flex;
  align-items: center;
}

.li-media-container {
  border: 1px solid grey;
  display: block;
  margin-bottom: 5px;
  padding: 5px;
  position: relative;

  .fa-trash-o {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .fa-download {
    position: absolute;
    right: 5px;
    bottom: 5px;
    cursor: pointer;
  }
}