$white: #FFFFFF;

$celtic: #16301A; // primary dark text
$mantis: #6BC048; // primary green
$screaminGreen: #82EF55; // primary light green

$hummingBird: #DCFAFA; // secondary light text
$iceCold: #B7F5F5; // secondary lighter text
$allports: #006AAC; // secondary blue
$regalBlue: #004B7A; // secondary dark blue

$mischka: #D1D6DF; // ghost grey
$eucalyptus: #259F4A; // ghost green
$hintOfGreen: #E8FFEF; // ghost light green
$hintOfRed: #ffe8e8;
$dangerRed: #ff0000
