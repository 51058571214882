@import 'color';

%li-button {
    background-color: $white;
    border-radius: 2px;
    border: 1px solid transparent;
    cursor: pointer;
    display: inline-block;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    min-width: 91px;
    padding: 6px 25px 8px;
    text-align: center;
}

.li-button--primary {
    @extend %li-button;

    background-color: $mantis;
    color: $celtic;

    &:hover {
        background-color: $screaminGreen;
        color: $celtic;
    }

    img {
        margin: -3px 12px 0 0;
    }
}

.li-button--secondary {
    @extend %li-button;

    background-color: $allports;
    color: $hummingBird;

    &:hover {
        background-color: $regalBlue;
        color: $iceCold;
    }
}

.li-button--ghost {
    @extend %li-button;

    border: 1px solid $mischka;
    color: $eucalyptus;

    &:hover {
        background-color: $hintOfGreen;
        border-color: transparent;
    }
}

.li-button--danger {
    @extend %li-button;

    border: 1px solid $mischka;
    color: $dangerRed;

    &:hover {
        background-color: $hintOfRed;
        border-color: transparent;
    }
}

.li-button--outline {
    @extend %li-button;

    border: none;
    background-color: transparent;

    &:hover {
        background-color: rgba(192, 189, 189, 0.66);
    }
}

.li-button--text {
    @extend %li-button;

    border: none;
    background-color: transparent;

    &:hover {
        background-color: rgba(192, 189, 189, 0.66);
    }
}

.li-button__slim {
    padding: 0;
}

.li-buttons-group {
    button {
        &:not(:last-child) {
            margin-right: 22px;
        }
    }
}

.li-buttons-group__slim {
    button {
        padding: 0;
        min-width: 60px;

        &:not(:last-child) {
            margin-right: 4px;
        }
    }
}

button:disabled{
      background-color: $mischka !important;
      color: black !important;
      cursor: not-allowed !important;
}