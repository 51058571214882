:root {
    --default-border-radius: 12px;
    --menu-item-bg-color: #fff;
    --menu-item-bg-color_hover: #ebebeb;
    --font-color-edit: #4b71fa;
}

#menu-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

}

.menu-wrapper {
    background-color: #fff;
    max-width: 900px;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: var(--default-border-radius);
    overflow: hidden;
}

.menu-builder-container {
    background-color: #f7f7f7;

    & .menu-builder__inner {
        padding-left: 0;
        padding-right: 0;
    }
}

.menu-item__inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;
}

.menu-item__inner .content {
    flex: 1;


    .secondary-details {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 12px;
        font-weight: 400px;
        color: #7d7d7d;
        justify-content: start;
        gap: 15px;
        margin-left: 15px;
    }
}

.menu-add__wrapper {

    & .menu-add__button {
        background-color: var(--menu-item-bg-color);
        display: block;
        width: 100%;
        border: none;
        text-align: start;
        color: var(--font-color-edit);
        font-size: 13px;
        font-weight: 400;
        padding-left: 15px;

        &:hover {
            background-color: var(--menu-item-bg-color_hover);
        }

        & .plus {
            margin-right: 15px;
        }
    }
}

.menu-meta {
    padding: 15px 10px 0;
}

.menu-meta input {
    border-radius: var(--default-border-radius);
}

.menu-item__wrapper {
    border-bottom-color: rgb(235, 235, 235);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-top-color: rgb(235, 235, 235);
    border-top-style: solid;
    border-top-width: 1px;
}

.menu-item__wrapper .menu-item__inner {
    background-color: var(--menu-item-bg-color);

}

.default-border {
    border-radius: var(--default-border-radius) !important;
}

.item-type__input,
.choices__inner .choices__input:not(.item-type__input) {
    border-radius: var(--default-border-radius) !important;
    width: 100%;
}

.item-type__menu {
    width: calc(100% - 30px) !important;
    border-radius: var(--default-border-radius) !important;

}


.modal-inner__container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;

    .modal-inner__header {
        flex: 0;
        display: flex;
        justify-content: space-between;

        .modal-button__close {

        }
    }

    .modal-inner__content {
        flex: 1 1;
        margin-top: 15px;
    }

    .modal-inner__footer {
        flex: 0;
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;

        .controls {
            width: 100%;
        }
    }
}

.menu-wrapper .save-bar,
.modal-inner__container .save-bar,
{
    display: flex;
    justify-content: space-between;
    padding: 8px 15px;
    background-color: #343434;
    color: #fff;

    .save-bar__buttons {
        button {
            color: inherit;
        }
    }

    &.saved {
        display: none;
    }

    &.unsaved {

    }

    &.errors {
        background-color: #b93a3a;
    }
}
